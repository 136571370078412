<template>
  <div>
    <loading v-if="loading" />
    <div v-if="! loading" class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="text-center pt-5">
            <img :src="user.avatar" :alt="user.name" height="100">
            <h4 class="mt-3">{{ user.name }}</h4>
          </div>
          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td width="30%">Role</td>
                  <td>{{ user.role }}</td>
                </tr>
                <tr>
                  <td>Last Seen</td>
                  <td>{{ user.last_seen }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td><span :class="`btn btn-sm btn-${ user.suspended ? 'danger' : 'success' }`">{{ user.suspended ? 'Suspended' : 'Active' }}</span></td>
                </tr>
              </tbody>
            </table>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <a href="#" class="btn btn-warning" @click.prevent="toggle" v-if="! user.suspended">Suspend</a>
                <a href="#" class="btn btn-warning" @click.prevent="toggle" v-if="user.suspended">Reactivate</a>
              </div>
              <div class="col-md-6 text-end">
                <a href="#" class="btn btn-danger" @click.prevent="confirmDelete">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <router-link :to="{ name: 'admin.users.edit' }" class="btn btn-info btn-sm float-end"><i class="lni lni-pencil me-2"></i>Edit</router-link>
            <h4 class="card-title mb-0">General Information</h4>
          </div>
          <div class="card-body">
            <table class="table table-bodred">
              <tbody>
                <tr>
                  <td width="30%">Email</td>
                  <td>{{ user.email }} <small class="text-muted ms-3">({{ user.email_verified ? 'verified' : 'Not Verified' }})</small></td>
                </tr>
                <tr>
                  <td width="30%">Phone Number</td>
                  <td>{{ user.phone_number }} <small class="text-muted ms-3" v-if="user.phone_number != null">({{ user.phone_number_verified ? 'verified' : 'Not Verified' }})</small></td>
                </tr>
                <tr>
                  <td>Personal Ads</td>
                  <td>{{ user.personal_ads }} <small class="text-muted ms-3">({{ user.personal_views }} views)</small></td>
                </tr>
                <tr>
                  <td>Agency Ads</td>
                  <td>{{ user.agency_ads }} <small class="text-muted ms-3">({{ user.agency_views }} views)</small></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">
            <h4 class="card-title mb-0">Agencies</h4>
          </div>
          <div class="card-body">
            <table class="table table-bodred">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Whatsapp</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="user.agencies.length == 0">
                  <td colspan="5">{{ user.name }} has no agencies</td>
                </tr>
                <tr v-for="(agency, i) in user.agencies" :key="`agency-${i}`">
                  <td>{{ i+1 }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.agencies.show', params: { id: agency.id }}">{{ agency.name }}</router-link>
                  </td>
                  <td>{{ agency.email }}</td>
                  <td>{{ agency.phone_number }}</td>
                  <td>{{ agency.whatsapp }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Property Ads</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Region</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Views</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="user.properties.length == 0">
                  <td colspan="8">There is no data to display</td>
                </tr>
                <tr v-for="(property, i) in user.properties" :key="`property-${i}`">
                  <td>{{ i+1 }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.properties.show', params: { id: property.id }}">{{ property.name }}</router-link>
                  </td>
                  <td>{{ property.type }}</td>
                  <td>{{ property.region }}</td>
                  <td>{{ property.amount }}</td>
                  <td>
                    <span :class="`btn btn-sm rounded-pill btn-${property.is_paid ? 'success' : 'danger'}`">{{ property.is_paid ? 'Yes' : 'No' }}</span>
                  </td>
                  <td>{{ property.views }}</td>
                  <td>
                    <span :class="`btn btn-sm rounded-pill btn-${property.status == 'pending' ? 'warning' : property.status == 'active' ? 'success' : 'danger'}`">{{ property.status }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      user: {},
      loading: true
    }
  },

  methods: {
    confirmDelete() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to undo this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete user!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy()
        }
      })
    },

    fetch() {
      this.loading = true
      this.axios.get(`/api/v1/admin/users/${this.$route.params.id}`).then(response => {
        this.user = response.data.user
        this.loading = false
      })
    },

    toggle() {
      this.axios.put(`/api/v1/admin/users/${this.$route.params.id}`).then(() => {
        this.fetch()
      })
    },

    destroy() {
      this.axios.delete(`/api/v1/admin/users/${this.$route.params.id}`).then(() => {
        this.$swal({
          title: "Success",
          text: "The user has been deleted",
          type: 'success'
        })
        this.$router.push({ name: 'admin.users.index' })
      })
    }
  }
}
</script>